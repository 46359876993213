import './about.css';

export default function About() {
  return (
    <div className="flex flex-col about min-h-screen text-center justify-center" id='about'>
      <div className='about-container'>
        <div className='pb-4'>
          <span className='aboutUsTitle'>ABOUT US</span>
        </div>
        <div className='aboutUsText items-center justify-center'>
          <span className='aboutUsDescription '>
            Nereus Technologies, based in <span className='aboutUsSpan'>Bangalore</span> specializes in <span className='aboutUsSpan'>wearable biomedical solutions</span> for athlete <span className='aboutUsSpan'>monitoring, simplifying clinical testing and analysis</span> into a single platform, making it easily accessible for the sports community.
          </span>
        </div>
        <div className='flex flex-col md:flex-row justify-evenly text-center mt-16'>
          <div className='flex flex-col items-center mb-8 md:mb-0'>
            <img src='./About/sriya.png' className='photo' alt=""></img>
            <span className='name text-white'>SRIYA PERI</span>
            <span className='post text-white'>Co-founder</span>
          </div>
          <div className='flex flex-col items-center'>
            <img src='./About/pari.png' className='photo' alt=''></img>
            <span className='name text-white'>PARINEETA MAHAJAN</span>
            <span className='post text-white'>Co-founder</span>
          </div>
        </div>
      </div>
    </div>
  )
}