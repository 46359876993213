import React, { useState } from 'react';

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav className="bg-transparent py-4 sm:py-6 lg:py-8">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center h-16 sm:h-20 lg:h-24">
          <a href="#hero" className="flex items-center">
            <img src='./Hero/logomark-white.png' className="h-10 w-10 sm:h-12 sm:w-12 lg:h-16 lg:w-16 logo" alt="Logo" />
          </a>
          <div className="hidden lg:flex items-center space-x-8 justify-end">
            <a href="#solutions" className="text-white text-xl hover:text-gray-300 transition-colors duration-300">Solutions</a>
            <a href="#ContactUs" className="px-4 py-2 text-sm font-semibold text-white border border-white rounded-full hover:bg-white hover:text-black transition-colors duration-300">Get in touch</a>
          </div>
          <div className="lg:hidden">
            <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="text-white p-2">
              {isMenuOpen ? (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 sm:h-10 sm:w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 sm:h-10 sm:w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>
      {isMenuOpen && (
        <div className="lg:hidden fixed inset-0 bg-black bg-opacity-90 z-50">
          <div className="flex flex-col items-center justify-center h-full space-y-8">
            <a href="#solutions" className="text-white text-2xl hover:text-gray-300 transition-colors duration-300">Solutions</a>
            <a href="#ContactUs" className="px-6 py-3 text-xl font-semibold text-white border border-white rounded-full hover:bg-white hover:text-black transition-colors duration-300">Get in touch</a>
            <button onClick={() => setIsMenuOpen(false)} className="mt-8 text-white">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 sm:h-12 sm:w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      )}
    </nav>
  );
}