import React from "react";
import Navbar from "./navbar";
import './Hero.css';
import HeroFooter from './herofooter';

export default function Hero() {
  return (
    <div className="flex flex-col">
      <div id="hero" className="min-h-screen flex flex-col bg-cover bg-center">
        <Navbar />
        
        <div className="flex-grow flex flex-col justify-center items-center text-center px-4 sm:px-6 md:px-8 lg:px-16 py-8">
        <div className="hero-title-container h-1/3 relative py-28">
          <h1 className="heroTitle text-white ">
            <span className="block">UNLOCK YOUR</span>
            <span className="block">FULL POTENTIAL</span>
          </h1>
          <span className="heroTitleBubble move">Move.</span>
          <span className="heroTitleBubble mend">Mend.</span>
          <span className="heroTitleBubble master">Master.</span>
        </div>
          <p className="heroDescription text-lg sm:text-lg md:text-xl text-white  mb-12 ">
            Bringing the pulse of clinical testing<br /> straight to the heart of the action.
          </p>
          <a className="inline-block mt-8 sm:mt-16" href="#solutions">
          <img src="./Hero/down-arrow.png" alt="Scroll down" className=" md:h-5 md:w-5 object-cover" />
        </a>

        </div>
      </div>
      <div id="footer">
        <HeroFooter />
      </div>
    </div>
  );
}