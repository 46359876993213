import { useState, FormEvent, ChangeEvent, useRef, useEffect } from 'react';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './contact.css';

emailjs.init("7sS5B1xeCnHs9JAaK");

interface UserData {
    [key: string]: string;
    name: string;
    email: string;
    message: string;
}

export default function ContactUs() {
    const [userData, setUserData] = useState<UserData>({
        name: '',
        email: '',
        message: ''
    });
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setUserData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        emailjs.send(
            'service_iwuw5c8',
            'template_oyjnuvr',
            userData,
            'dHqfCIdS3RjA4Eob5'
        )
        .then((result) => {
            console.log('Email sent successfully:', result.text);
            setUserData({ name: '', email: '', message: '' });
            toast.success('Message sent successfully!');
        }, (error) => {
            console.error('Failed to send email:', error.text);
            toast.error('Failed to send message. Please try again.');
        });
    };

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [userData.message]);

    return (
        <div className='contactUs' id='ContactUs'>
            <div className='p-12 pb-0 text-center'>
                <h1 className='getInTouch'>GET IN TOUCH</h1>
            </div>
            <form onSubmit={handleSubmit} className='flex flex-col items-center'>
                <div className='form flex flex-col md:flex-row mt-10'>
                    <div className='flex flex-col'>
                        <input
                            className='bg-transparent'
                            id='name'
                            type='text'
                            placeholder='Your name'
                            name='name'
                            value={userData.name}
                            onChange={handleInputChange}
                            required
                        />
                        <input
                            className='bg-transparent'
                            type='email'
                            id='email'
                            placeholder='Email address'
                            name='email'
                            value={userData.email}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className='flex flex-col md:ml-20'>
                        <textarea
                            ref={textareaRef}
                            className='bg-transparent'
                            id='message'
                            placeholder='Write us a message...'
                            name='message'
                            value={userData.message}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                </div>
                <div className='flex justify-center mt-9'>
                    <button type='submit' className='submit'>Submit</button>
                </div>
            </form>
            <div className='flex flex-col md:flex-row justify-between items-center mt-8'>
                <div className='flex justify-center' style={{ width: '200px' }}>
                    <a href='https://www.instagram.com/nereustechnologies?igsh=MTVjbWhlb2tlbHBuag=='><img src='./Contact Us/instagram.png' alt='' className='contactIcon'></img></a>
                    <a href='https://www.linkedin.com/company/nereus-technologies-pvt-ltd/'><img src='./Contact Us/linkedin.png' alt='' className='contactIcon'></img></a>
                    <a href='#a'><img src='./Contact Us/facebook.png' alt='' className='contactIcon'></img></a>
                </div>
                <div className='mt-4'>
                    <span className='moveMendMaster'>Move. Mend. Master.</span>
                </div>
                <div className='mt-4 md:mt-0'>
                    <p id="nereus">
                        NEREUS
                    </p>
                    <span id='technologies'>
                        TECHNOLOGIES
                    </span>
                </div>
            </div>
            <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </div>
    );

}
