import './features.css';

export default function Features() {
    return (
        <div className="features text-center justify-center flex flex-col align-center" id='features'>
            <div className='pb-20'>
                <span className='title text-white mt-10 py-2 inline-block'>WHAT IS INCLUDED?</span>
                <div className='text-white description items-center justify-center mt-8 px-4 md:px-20'>
                <span className='featuresDescription  '>
                    We employ advanced technology enabling immediate player assessment,
                    <br className="hidden md:block" />
                    ensuring proactive management of performance and health.
                </span>
</div>

                
            </div>
            <div className='flex flex-col cont justify-center flex-grow position-relative mb-20'>
                <div className='icons-container'>
                    <img src='./Features/line.png' alt='line' className='line' />
                    <div className='feature-item' style={{ marginTop: '30px' }}>
                        <img src='./Features/icon-1.png' alt='icon-1' className='icons' />
                        <span className='text-white icon-description px-4'>Motion and Muscle Monitoring Sensors</span>
                    </div>
                    <div className='feature-item' style={{ marginTop: '30px' }}>
                        <img src='./Features/icon-2.png' alt='icon-2' className='icons' />
                        <span className='text-white icon-description px-4'>One-stop application for managing athlete data and analysis</span>
                    </div>
                    <div className='feature-item' style={{ marginTop: '30px' }}>
                        <img src='./Features/icon-3.png' alt='icon-3' className='icons' />
                        <span className='text-white icon-description px-4'>Cardiac health and Location Monitoring Sensor</span>
                    </div>
                </div>
            </div>
        </div>
    );
}