import React from "react";
import './solutions.css'

export default function Solutions() {
    return (
        <div className="solutions-container flex flex-col justify-center py-8 md:py-20 bg-black" id='solutions'>
            <div className="text-start px-4 mt-5 md:px-20 lg:px-80">
                <span className="spotlight text-xl sm:text-2xl md:text-3xl">
                    REVOLUTIONISING ATHLETE MONITORING
                </span>
            </div>
            <div className="board py-4 flex flex-col md:flex-row justify-center px-4 md:px-8 lg:px-[17.5%] space-y-4 md:space-y-0 md:space-x-4">
                <div className="column w-full md:w-1/3 flex flex-col space-y-4">
                    <div className="left1 p-4 md:p-6 rounded-2xl bg-cover bg-center">
                        <span id='analyse'>ANALYSE</span>
                        <p className="analyse-subtext">Real time vitals, performance and limb assessment data.</p>
                    </div>
                    <div className="left2 text-white p-4 rounded-2xl bg-cover bg-center">
                        <span className="elevate">ELEVATE YOUR</span>
                        <div className="w-1/4 h-1 bg-sky-400 inline-block mx-2"></div>
                        <span className="elevate">GAME.</span>
                    </div>
                    <div className="left3 p-4 rounded-2xl text-white text-center bg-cover bg-center">
                        <span>MASTER</span>
                        <img src="/Solutions/left3.png" alt="" className="inline-block mx-2 h-6 sm:h-8" />
                        <span>PRECISION.</span>
                    </div>
                </div>
                <div className="column w-full md:w-1/3 flex flex-col space-y-4">
                    <div className="middle1 p-4 rounded-2xl text-white flex flex-col justify-between bg-cover bg-center">
                        <div>
                            <p className="middle1_text">
                                GET REAL TIME DATA & 
                                <span className="inline-block text-xl md:text-3xl bg-sky-400 rounded-xl px-2 py-1 ml-2">ANALYTICS</span>
                                TO AID YOU
                            </p>
                            <p className="middle1_subtext mt-2">
                                All via our customizable application for a personalised user experience
                            </p>
                        </div>
                        <img src="/Solutions/middle1_nut.png" alt="" className="self-start mt-4 w-1/4 sm:w-auto" />
                    </div>
                    <div className="middle2 p-4 rounded-2xl text-right bg-cover bg-left">
                        <p id='optimise'>OPTIMISE</p>
                        <p className="optimise_subtext">Training and coaching strategies</p>
                    </div>
                </div>
                <div className="column w-full md:w-1/3 flex flex-col space-y-4">
                    <div className="right1 p-4 rounded-2xl text-white flex flex-col justify-between bg-cover bg-center">
                        <div className="circle w-5 h-5 sm:w-7 sm:h-7 rounded-full bg-sky-400"></div>
                        <div>
                            <p className="optimise_subtext">Combining insights from nearly</p>
                            <p className='parameters'>25 Parameters</p>
                        </div>
                    </div>
                    <div className="right2 p-4 rounded-2xl bg-cover bg-right">
                        <span id='overcome'>OVERCOME</span>
                        <p className="right_subtext">Injury and performance hurdles</p>
                    </div>
                    <div className="right3 p-4 rounded-2xl text-white bg-cover bg-center">
                        <div className="text-right">
                            <img src="/Solutions/right3_star.png" alt="" className="inline-block mr-2 w-5 sm:w-auto" />
                            <span>PREVENT</span>
                        </div>
                        <div className="text-center">
                            <span>INJURIES.</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-right px-4 md:px-20 lg:px-80">
                <span className="spotlight text-xl sm:text-2xl md:text-3xl">
                    WITH BIOMEDICAL SOLUTIONS
                </span>
            </div>
            <div className="mt-10"> 
            </div>
        </div>
    )
}